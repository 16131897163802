<template>
  <div class="grey lighten-3 pa-2" style="position:relative">
    <swiper :options="swiperOptions" style="padding:20px 0 40px">
      <swiper-slide v-for="leaflet in leaflets" :key="leaflet.leafletId">
        <v-card
          :to="{
            name: 'Leaflet',
            params: {
              leafletId: leaflet.slug
                ? leaflet.slug
                : leaflet.leafletId.toString()
            }
          }"
        >
          <v-img :src="`${leaflet.baseLocation}${leaflet.firstPagePreview}`" />
        </v-card>
        <div class="leaflet-descr">
          <h3 class="mt-1 mt-sm-2 mt-md-0">{{ leaflet.title }}</h3>
          <div>
            Dal
            {{ leaflet.fromDate | dayjs("format", "D  MMMM") }} al
            {{ leaflet.toDate | dayjs("format", "D  MMMM") }}
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-next">
      <v-icon color="primary">icon-arrow-forward</v-icon>
    </div>
    <div class="swiper-button-prev">
      <v-icon color="primary">icon-arrow-back</v-icon>
    </div>
  </div>
</template>
<style lang="scss">
.leaflets-container {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .leaflet-descr {
    h3 {
      line-height: 1.7rem !important;
      height: 75px;
    }
  }
}
</style>
<script>
import leafletService from "~/service/leafletService.js";

export default {
  name: "LeafletsSlider",
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        // breakpoints: {
        //   0: {
        //     slidesPerView: 2
        //   },
        //   600: {
        //     slidesPerView: 2
        //   },
        //   960: {
        //     slidesPerView: 3
        //   },
        //   1264: {
        //     slidesPerView: 4
        //   },
        //   1900: {
        //     slidesPerView: 4
        //   }
        // },
        navigation: {
          prevEl: `.swiper-button-prev`,
          nextEl: `.swiper-button-next`
        }
      },
      leaflets: []
    };
  },
  props: {
    parentWarehouseId: { type: Number, required: false },
    parentLeafletTypeId: { type: Number, required: false }
  },
  async mounted() {
    this.leaflets = await leafletService.search({
      parent_warehouse_id: this.parentWarehouseId,
      parent_leaflet_type_id: this.parentLeafletTypeId
    });
    if (this.leaflets.length == 1) {
      this.$router.push({
        name: "Leaflet",
        params: {
          leafletId: this.leaflets[0].slug
            ? this.leaflets[0].slug
            : this.leaflets[0].leafletId.toString()
        }
      });
    }
  }
};
</script>
