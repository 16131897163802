var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey lighten-3 pa-2",staticStyle:{"position":"relative"}},[_c('swiper',{staticStyle:{"padding":"20px 0 40px"},attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.leaflets),function(leaflet){return _c('swiper-slide',{key:leaflet.leafletId},[_c('v-card',{attrs:{"to":{
          name: 'Leaflet',
          params: {
            leafletId: leaflet.slug
              ? leaflet.slug
              : leaflet.leafletId.toString()
          }
        }}},[_c('v-img',{attrs:{"src":`${leaflet.baseLocation}${leaflet.firstPagePreview}`}})],1),_c('div',{staticClass:"leaflet-descr"},[_c('h3',{staticClass:"mt-1 mt-sm-2 mt-md-0"},[_vm._v(_vm._s(leaflet.title))]),_c('div',[_vm._v(" Dal "+_vm._s(_vm._f("dayjs")(leaflet.fromDate,"format", "D MMMM"))+" al "+_vm._s(_vm._f("dayjs")(leaflet.toDate,"format", "D MMMM"))+" ")])])],1)}),1),_c('div',{staticClass:"swiper-button-next"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-arrow-forward")])],1),_c('div',{staticClass:"swiper-button-prev"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-arrow-back")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }